/* HeroesComponent's private CSS styles */
.menu {
    margin: 0 0 2em 0;
    list-style-type: none;
    padding: 0;
    width: 15em;
}
.menu li {
    cursor: pointer;
    position: relative;
    left: 0;
    background-color: #EEE;
    margin: .5em;
    padding: .3em 0;
    height: 3em;
    border-radius: 4px;
}
.menu li:hover {
    color: #607D8B;
    background-color: #DDD;
    left: .5em;
}
.menu li.selected {
    background-color: #CFD8DC;
    color: white;
    left: .5em;
}
.menu li.selected:hover {
    background-color: #BBD8DC;
    color: white;
}
.menu .badge {
    display: inline-block;
    font-size: small;
    color: white;
    padding: 0.8em 0.7em 0 0.7em;
    background-color:#405061;
    line-height: 1em;
    position: relative;
    left: -1px;
    top: -4px;
    height: 3em;
    margin-right: .8em;
    border-radius: 4px 0 0 4px;
}
