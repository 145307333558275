
.page-container {
    margin: 20px;
}

table {
    border-collapse: collapse;
    width: 800px;
}
.cell > tbody > tr > td, .cell thead > tr > th{
    text-align: left;
    padding: 0 .5rem !important;
    vertical-align: center;
}

td,
th {
    padding: 8px;
    text-align: left;
   /* border: 1px solid silver;*/
    min-width: 100px;
}

th {
    color: white;
    background: #535353;
    border: 1px solid silver;
}
/*
th:first-child {
    border-top-left-radius:5px;
}*/
tr:hover td{
    background-color: rgba(0,0,0,0.25);
}

pre {
    margin: 20px;
    padding: 10px;
    background: #eee;
    border: 1px solid silver;
    border-radius: 4px;
}

/*
   this is important!
   make sure you define this here
   or in jQuery codef
*/
.resizer {
    position: absolute;
    top: 0;
    right: -8px;
    bottom: 0;
    left: auto;
    width: 16px;
    cursor: col-resize;
}
/*
.rc-handle-container {
    position: relative;
}
.rc-handle {
    position: absolute;
    width: 7px;
    cursor: ew-resize;
    margin-left: -3px;
    z-index: 2;
}
table.rc-table-resizing {
    cursor: ew-resize;
}
table.rc-table-resizing thead,
table.rc-table-resizing thead > th,
table.rc-table-resizing thead > th > a {
    cursor: ew-resize;
}
*/
